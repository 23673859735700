import React from 'react'

import { Pages } from '../enums'

import siteData from '../content/data.json'

import DefaultLayout from '../templates/DefaultLayout'
import Section from '../components/Section'
import Container from '../components/Container'
import Address from '../components/Address'

import '../scss/styles.scss'

const Contact = () => {
  return (
    <DefaultLayout title={siteData.contact.title} activePage={Pages.CONTACT}>
      <Section>
        <Container>
          <h1 className="title">{siteData.contact.headline}</h1>
          <Address address={siteData.contact.address} />
        </Container>
      </Section>
    </DefaultLayout>
  )
}

export default React.memo(Contact)
