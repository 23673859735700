import React from 'react'

type AddressProps = {
  address: any
}

const Address = ({ address }: AddressProps) => {
  return (
    <address>
      <ul>
        {address.name && <li className="name">{address.name}</li>}
        {address.nameSecond && <li className="name">{address.nameSecond}</li>}
        {address.info && <li className="info">{address.info}</li>}
        {address.street && <li className="street">{address.street}</li>}
        {address.city && <li className="city">{address.city}</li>}
        {address.phone && (
          <li className="phone">
            <div className="icon">
              <span className="fa fa-phone"></span>
            </div>
            Tel.: {address.phone}
          </li>
        )}
        {address.fax && (
          <li className="fax">
            <div className="icon">
              <span className="fa fa-fax"></span>
            </div>
            Fax: {address.fax}
          </li>
        )}
        {address.mail && (
          <li className="mail">
            <div className="icon">
              <span className="fa fa-envelope"></span>
            </div>
            <a href={`mailto:${address.mail}`}>{address.mail}</a>
          </li>
        )}
        {address.web && (
          <li className="website">
            <div className="icon">
              <span className="fa fa-globe"></span>
            </div>
            <a href={`http://${address.web}`} target="_blank">
              {address.web}
            </a>
          </li>
        )}
        {address.delivery && (
          <li className="delivery">Liefert: {address.delivery}</li>
        )}
      </ul>
    </address>
  )
}

export default React.memo(Address)
